/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useState, useEffect } from "react";
import useAuthStore from "./Stores/AuthStore";
import axios from "axios";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	const [socket, setSocket] = useState(false);
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { isAuth } = useAuthStore();

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
		},
		{
			name: "Students",
			url: "/students",
		},
		{
			name: "Classes",
			url: "/classes",
		},
		{
			name: "Events",
			url: "/events",
		},
		{
			name: "Parents",
			url: "/parents",
		},
		{
			name: "Teachers",
			url: "/teachers",
		},
		{
			name: "Subjects",
			url: "/subjects",
		},
		{
			name: "My Profile",
			url: "/my-profile",
		},
	];

	let nairaSign = <span className="fontInherit">&#8358;</span>;
	let nairaSignNeutral = "₦",
		[countryState, setCountryState] = useState([]);

	// let getStates = async () => {
	// 	let url = `https://nigeria-states-towns-lga.onrender.com/api/states`;
	// 	// try {
	// 	// 	let res = await axios.get(url);
	// 	// 	setCountryState(res?.data);
	// 	// } catch (error) {
	// 	// 	console.log({ error }, "country");
	// 	// }
	// 	try {
	// 		const response = await fetch(url, {
	// 			method: "get",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			},
	// 		});
	// 		const responseData = await response.json();
	// 		// console.log({ responseData });
	// 		setCountryState(responseData);
	// 	} catch (error) {
	// 		console.log({ error }, "country", "fetch");
	// 	}
	// };
	let getStates = async () => {
		try {
			let res = await axios.get(`https://nga-states-lga.onrender.com/fetch`, {
				headers: {
					Authorization: null,
					companyid: null,
				},
			});
			setCountryState(
				res?.data?.map(it => {
					return { name: it };
				})
			);
		} catch (error) {
			console.log({ error }, "country");
		}
	};
	useEffect(() => {
		getStates();
	}, []);

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth: isAuth,
		nav,
		toggleNav,
		nairaSign,
		nairaSignNeutral,
		countryState,
		socket,
		setSocket,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
